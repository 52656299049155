import Header from '../components/global/header'
import Main from '../components/home/main'
import Footer from '../components/global/footer'

function Home() {
    return (
  <>
    <Header/>
    <Main/>
    <Footer/>
  </>
    );
  }
  
  export default Home;
  