import Header from '../components/global/header'
import Footer from '../components/global/footer'

export default function About() {
    return (
      <>
      <Header/>
      <section>
            <div class="container mx-auto px-8 bg-slate-700">

            <div class="flex items-center flex-col justify-center lg:flex-row py-28 px-6 md:px-24  gap-16 lg:gap-28">
            <div class="w-full lg:w-1/2">
                <img class="hidden lg:block" src="/error.png" />
                <img class="hidden md:block lg:hidden" src="/error.png" />
                <img class="md:hidden" src="/error.png" />
            </div>
            <div class="w-full lg:w-1/2">
                <h1 class="py-4 text-3xl lg:text-4xl font-extrabold text-gray-800 dark:text-white">Looks like you've found the doorway to the great nothing</h1>
                <p class="py-4 text-base text-gray-800 dark:text-white">The content you’re looking for doesn’t exist. Either it was removed, or you mistyped the link.</p>
                <p class="py-2 text-base text-gray-800 dark:text-white">Sorry about that! Please visit our homepage to get where you need to go.</p>
                <button class="w-full lg:w-auto my-4 rounded-md px-1 sm:px-16 py-5 bg-slate-600 text-white hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-700 focus:ring-opacity-50"><a href="/">Go back to homepage</a></button>
            </div>
        </div>
    
            </div>
      </section>
      <Footer/>
      </>
    );
  }