export default function Footer() {
    return (
  <>
  {/* HOME FOOTER */}
  <section>
      <div class="mx-auto max-w-7xl px-6 lg:px-2 fixed inset-x-0 bottom-0">
        <div class="pointer-events-none absolute inset-0 z-30 shadow"></div>
        <div class="relative z-20">
          <div class="mx-auto flex max-w-7xl items-center justify-between px-4 py-5 sm:px-6 sm:py-4 lg:px-8">
            <div class="flex space-x-9">
            <a href="/privacy">Privacy</a>
            <a href="/terms">Terms</a>
            </div>
            <div class="">
            <a href="/contact">Contact</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
    );
  }
  
