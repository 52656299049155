

export default function AboutHero() {
    return (
      <>
      <section class="">
        <div class="container mx-auto px-8 py-8">
          <div class="md:flex items-center justify-between">
              <div class="xl:w-5/12 md:w-1/2 xl:pr-16 md:pr-8">
                  <h1 role="heading" class="lg:text-5xl text-3xl font-bold leading-tight dark:text-black">About Draunt</h1>
                  <p role="contentinfo" class="mt-4 text-base leading-6">Were a tech company in Washington, USA that is dedicated to creating great software to improve lives.</p>
                  <button role="button" aria-label="explore more" class="sm:w-auto w-full mt-10 text-base font-medium leading-none text-white bg-slate-700 rounded md:p-4 p-6 focus:ring-2 focus:ring-offset-2 focus:ring-slate-700 hover:bg-slate-600"><a href="/software">Explore Our Software</a></button>
              </div>
              <div class="xl:w-2/5 md:w-1/2 w-full md:mt-0 mt-4">
                  <img src="/about.webp" alt="woman working" class="md:w-auto w-full" />
              </div>
          </div>
{/* 
          <div class="flex flex-wrap md:mt-16 mt-8 gap-6 flex items-center justify-between">
              <div>
                  <div class="flex items-center">
                      <div class="w-10 h-10 bg-slate-700 rounded-full text-base font-semibold leading-4 text-white flex items-center justify-center">1</div>
                      <h2 class="ml-4 text-base font-semibold leading-4 dark:text-black">Extraordinary Performance</h2>
                  </div>
                  <p class="sm:w-72 w-full text-base mt-5 leading-6">A phrase is a short selection of words which when put together create a concept.</p>
              </div>
              <div>
                  <div class="flex items-center">
                      <div class="w-10 h-10 bg-slate-700 rounded-full text-base font-semibold leading-4 text-white flex items-center justify-center">2</div>
                      <h2 class="ml-4 text-base font-semibold leading-4 dark:text-black">Extraordinary Performance</h2>
                  </div>
                  <p class="sm:w-72 w-full text-base mt-5 leading-6">A phrase is a short selection of words which when put together create a concept.</p>
              </div>
              <div>
                  <div class="flex items-center">
                      <div class="w-10 h-10 bg-slate-700 rounded-full text-base font-semibold leading-4 text-white flex items-center justify-center">3</div>
                      <h2 class="ml-4 text-base font-semibold leading-4 dark:text-black">Extraordinary Performance</h2>
                  </div>
                  <p class="sm:w-72 w-full text-base mt-5 leading-6">A phrase is a short selection of words which when put together create a concept.</p>
              </div>
          </div>
*/}
        </div>
      </section>

      </>
    );
  }