import Header from '../components/global/header'
import Footer from '../components/global/footer'
import PrivacyHero from '../components/privacy/privacy-hero'

export default function About() {
    return (
      <>
      <Header/>
      <PrivacyHero/>
 
      </>
    );
  }