import Header from '../components/global/header'
import Footer from '../components/global/footer'
import AboutHero from '../components/about/about-hero'

export default function About() {
    return (
      <>
      <Header/>
      <AboutHero/>
      <Footer/>
      </>
    );
  }