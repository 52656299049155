import Header from '../components/global/header'
import Footer from '../components/global/footer'
import TermsHero from '../components/terms/terms-hero'

export default function About() {
    return (
      <>
      <Header/>
      <TermsHero/>
    
      </>
    );
  }