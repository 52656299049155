export default function Header() {
  console.log(window.location)
    return (
  <>
   {/* HOME HEADER */}
   <section>
      <div class="mx-auto max-w-7xl px-6 lg:px-2">
        <div class="pointer-events-none absolute inset-0 z-30"></div>
        <div class="relative z-20">
          <div class="mx-auto flex max-w-7xl items-center justify-between px-4 py-5 sm:px-6 sm:py-4 lg:px-8">

            <div class="flex space-x-9">
            {window.location.pathname==="/" ?
              <></>
              :
              <a href="/">Home</a>
            }
            <a href="/about">About</a>
            </div>
            <div>
            <a href="/software">Software</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
    );
  }
  
