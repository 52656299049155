export default function Header() {
    return (
  <>
   {/* HOME MAIN */}
   <section>
    <div class="mx-auto max-w-7xl px-6 lg:px-2">
    <div class="flex justify-center mt-40">
      <div class="">
        <img src="/draunt-b.png" alt="Draunt" class="scale-50 lg:scale-75 translate-y-8" style={{ }}/>
        <div class="flex items-center justify-center relative flex-wrap mb-4">
          <input type="search" class="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-norma border border-solid border-gray-300 rounded transition ease-in-out m-0" placeholder="type your query" aria-label="Search" aria-describedby="button-addon2"/>
          <button class="mt-2 inline-block px-6 py-2.5 bg-slate-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-slate-800 active:shadow-lg transition duration-150 ease-in-out flex items-center" type="button" id="button-addon2">
                <svg aria-hidden="true" class="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
              </svg>
            <p class="ml-2">Search</p>
          </button>
          <button class="ml-2 mt-2 inline-block px-6 py-2.5 bg-slate-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-slate-800 active:shadow-lg transition duration-150 ease-in-out flex items-center" type="button" id="button-addon2">
            <p class="ml-2">I'm Feeling Lucky</p>
          </button>
        </div>
      </div>
    </div>
    </div>
  
    </section>
  </>
    );
  }
  
