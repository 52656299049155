import Header from '../components/global/header'
import Footer from '../components/global/footer'
import SoftwareHero from '../components/software/software-hero'

export default function About() {
    return (
      <>
      <Header/>
      <SoftwareHero/>
      <Footer/>
      </>
    );
  }