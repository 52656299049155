

export default function SoftwareHero() {
    return (
      <>
      <section class="">
        <div class="container mx-auto px-8">
          <h1 class="text-center text-2xl pt-4 underline">Our Software</h1>
          <p class="mt-4 text-left lg:text-center">Were a software company based in Washington, USA creating tech to improve lives.</p>


        <div aria-label="group of cards" class="focus:outline-none mx-auto container pt-4">
            <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8 lg:pb-10">
                <div aria-label="card 1" class="focus:outline-none bg-white dark:bg-slate-700 px-6 py-6 text-color f-f-l shadow-lg">
                    <h1 class="focus:outline-none dark:text-white text-3xl font-bold w-1/2">Search</h1>
                    <div aria-label="download icon" role="img" class="focus:outline-none flex justify-end w-full items-end">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>

                    </div> 
                </div>
                <div aria-label="card 2" class="focus:outline-none bg-white dark:bg-slate-700 px-6 py-6 text-color f-f-l shadow-lg">
                    <h1 class="focus:outline-none dark:text-white text-3xl font-bold w-1/2">Education</h1>
                    <div aria-label="onboarding icon " role="img" class="focus:outline-none flex justify-end w-full items-end">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                    </svg>
                    </div>
                </div>
                <div aria-label="card 3" class="focus:outline-none bg-white dark:bg-slate-700 px-6 py-6 text-color f-f-l shadow-lg">
                    <h1 class="focus:outline-none dark:text-white text-3xl font-bold w-10/12">Finance</h1>
                    <div aria-label="responsive icon" role="img" class="focus:outline-none flex justify-end w-full items-end">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                    </svg>
                    </div>
                </div>
                {/*
                <div aria-label="card 4" class="focus:outline-none bg-white dark:bg-slate-800 px-6 py-6 text-color f-f-l shadow-lg">
                    <h1 class="focus:outline-none dark:text-white text-3xl font-bold w-9/12">Draunt Apps</h1>
                    <div aria-label="success icon" role="img" class="focus:outline-none flex justify-end w-full items-end">
                      <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/minimal_cards_with_heading_and_icon-svg4.svg" alt="rapidly"/>
                    </div>
                </div>
                */}
            </div>
        </div>
    
        </div>
      </section>

      </>
    );
  }