import Header from '../components/global/header'
import Footer from '../components/global/footer'
import ContactHero from '../components/contact/contact-hero'

export default function Contact() {
    return (
      <>
      <Header/>
      <ContactHero/>
      {window.location.pathname==="/contact" ?
      <></>
      :
      <Footer/>
      }
      </>
    );
  }